// eslint-disable-next-line import/prefer-default-export
export const columnConfig = [
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'bg-[#F4F4F4] font-semibold text-left text-capitalize !text-black',
    tdClass: 'text-black text-left align-top',
  },
  {
    key: 'variant',
    label: 'Variasi',
    thClass: 'bg-[#F4F4F4] font-semibold text-left text-capitalize !text-black',
    tdClass: 'text-black text-left align-top',
  },
  {
    key: 'stock',
    label: 'Stok Produk',
    thClass: 'bg-[#F4F4F4] font-semibold text-left text-capitalize !text-black',
    tdClass: 'text-black text-left align-top',
  },
  {
    key: 'sold',
    label: 'Total Terjual',
    thClass: 'bg-[#F4F4F4] font-semibold text-left text-capitalize !text-black',
    tdClass: 'text-black text-left align-top',
  },
  {
    key: 'packing_material',
    label: 'Bahan Packing',
    thClass: 'bg-[#F4F4F4] font-semibold text-left text-capitalize !text-black',
    tdClass: 'text-black text-left align-top',
  },
  {
    key: 'fulfillment_fee',
    label: 'Biaya Fulfillment',
    thClass: 'bg-[#F4F4F4] font-semibold text-left text-capitalize !text-black',
    tdClass: 'text-black text-left align-top',
  },
  {
    key: 'additional_fee',
    label: 'Biaya Tambahan',
    thClass: 'bg-[#F4F4F4] font-semibold text-left text-capitalize !text-black',
    tdClass: 'text-black text-left align-top',
  },
]

export const fieldsBundling = [
  {
    key: 'no',
    label: 'No',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
    tdClass: 'text-[#828282]',
  },
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
    tdClass: 'text-[#828282]',
  },
  {
    key: 'qty',
    label: 'Jumlah',
    thClass:
'font-[500] text-[#222] !text-[14px] !capitalize text-center bg-white',
    tdClass: 'text-[#828282]',
  },
]
